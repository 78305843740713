#footer {
  background-color: var(--theme-footer-bg-color);
  display: inline-block;
  width: 100%;

  .footer-title {
    margin: 10px 0px;
    text-align: start;
    font-size: 20px;
    color: var(--theme-footer-title-color);

    @media screen and (max-width: 768px) {
      font-size: 18px;
      margin: 20px 0px 10px;
    }

    @media screen and (max-width: 575px) {
      font-size: 16px;
      margin: 20px 0px 10px;
    }
  }

  .footer-text {
    .ic_home,
    .address {
      color: var(--theme-footer-text-color);
    }
    a {
      color: var(--theme-footer-text-color);
      &.Footer-Active-Color {
        color: var(--theme-footer-hover-color);
      }
    }
  }

  .footer-subcribe {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .inputText {
      border: 1px solid var(--theme-footer-hover-color);
      color: var(--theme-footer-hover-color);
      width: 100%;
      outline: none;
      padding: 9px 10px 9px 10px;
      border-radius: 4px 0 0 4px;
      max-width: 220px;
      font-size: 14px;
      height: 42px;

      &::placeholder {
        color: var(--theme-footer-hover-color);
      }
    }

    .buttonubscribe {
      background-color: var(--theme-btn-primary-bg-color);
      border-color: var(--theme-btn-primary-bg-color);
      color: var(--theme-btn-primary-text-color);
      width: auto;
      margin-left: -1px;
      border-radius: 0 4px 4px 0;
      font-size: 13px;
      padding: 10px 20px;
      &:hover {
        border: 1px solid var(--theme-btn-primary-hover-color);
      }
    }
  }

  .gridView {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  ul {
    li {
      transition: 0.3s ease all;

      .address,
      a {
        font-size: 15px;
        display: inline-block;

        @media screen and (max-width: 768px) {
          font-size: 14px;
        }

        @media screen and (max-width: 575px) {
          font-size: 12px;
        }

        &:hover {
          color: var(--theme-footer-hover-color);
        }
      }

      &:hover {
        color: var(--theme-footer-hover-color);
      }
    }
  }

  .footer-bottom {
    background-color: var(--theme-top-header-bg-color);
    padding: 10px 0;
    text-align: center;
    a{
      color: var(--theme-top-header-text-color);
      &:hover{
        color: var(--theme-footer-hover-color);
      }
    }
    p {
      margin-bottom: 0;
      font-size: 12px;
      color: var(--theme-top-header-text-color);
     
    }
  }

  .social-icon {
    display: flex;
    align-items: center;

    .d-flex {
      margin-right: 10px;
    }

    .icon_img {
      position: relative;

      img {
        width: 32px;
        height: 32px;
        overflow: hidden;
        border-radius: 5px;
      }

      .icon_text {
        position: absolute;
        top: 100%;
        left: 50%;
        background-color: var(--theme-footer-title-color);
        border-radius: 5px;
        transform: translateX(-50%);
        opacity: 0;
        padding: 0;
        visibility: hidden;
        transition: 0.3s ease all;

        P {
          font-size: 12px;
          color: var(--theme-footer-bg-color);
        }

        &::after {
          content: "";
          border-style: solid;
          border-width: 1px;
          border-color: var(--theme-footer-title-color);
          position: absolute;
          bottom: 65%;
          left: 50%;
          transform: rotate(45deg) translateX(-50%);
          background: var(--theme-footer-title-color);
          width: 10px;
          height: 10px;
          z-index: 11000;
        }
      }

      &:hover {
        .icon_text {
          opacity: 1;
          visibility: visible;
          padding: 5px 10px;
        }
      }
    }
  }
}
