#header {
  box-shadow: 1px 1px 10px rgb(206 206 206 / 40%);
  position: relative;

  .TopHeader-Left {
    a {
      font-size: 13px;
      color: var(--theme-top-header-text-color);

      &:hover {
        color: var(--theme-top-header-hover-color);
      }
    }
  }
  .main_header {
    .menu-icons {
      .nav-link {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--theme-Second-header-title-color);

        &:hover {
          color: var(--theme-Second-header-hover-color);
        }

        .user-icon {
          display: flex;
          align-items: center;
          position: relative;
          font-size: var(--fs-14);
          min-width: 45px;
          justify-content: flex-end;

          .user-dropdown {
            display: none;
            visibility: hidden;
            width: 280px;
            position: absolute;
            top: 0%;
            right: 0%;
            z-index: 9999;
            background-color: var(--theme-body-bg-color);
            box-shadow: 1px 1px 10px rgb(206 206 206 / 40%);
            transition: 0.3s ease all;
            padding: 0;

            li {
              padding: 12px 20px;

              .SignInUp {
                color: var(--theme-Second-header-text-color);
                font-size: var(--fs-15px);
                cursor: pointer;

                &:hover {
                  color: var(--theme-Second-header-hover-color);
                }

                i {
                  margin-right: 10px;
                }
              }

              &:first-child {
                border-bottom: 1px solid var(--theme-Second-header-title-color);
                font-weight: var(--fw-700);

                .SignInUp {
                  font-size: var(--fs-16px);
                  color: var(--theme-Second-header-sub-title-color);
                }
              }
            }

            &::after {
              content: "";
              position: absolute;
              top: -2%;
              right: 3px;
              width: 20px;
              height: 20px;
              z-index: 999;
              transform: rotate(45deg);
              background: var(--theme-body-bg-color);
            }
          }

          &:hover {
            .user-dropdown {
              top: 100%;
              display: block;
              visibility: visible;

              @media (max-width: 575px) {
                right: auto;
                left: 25px;
                padding-top: 5px;

                &::after {
                  right: auto;
                  left: 5px;
                }
              }
            }
          }

          .user-text {
            color: var(--theme-Second-header-title-color);
            margin-right: 5px;
          }
        }
      }

      .secondHeaderRightSide {
        font-size: var(--fs-16px);
        position: relative;

        &:hover {
          color: var(--theme-Second-header-hover-color);
        }

        .count-icon {
          position: absolute;
          top: -15px;
          right: -12px;
          width: 20px;
          height: 20px;
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          background-color: var(--theme-top-header-bg-color);
          border-radius: 50%;
          color: var(--theme-top-header-text-color);
          font-style: normal;
          font-size: 10px;
          transition: 0.3s ease-in-out all;
        }
      }
    }
  }

  .TopHeader-Right {
    .user-name {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: end;
      cursor: pointer;

      li {
        .user-icon {
          position: relative;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-size: var(--fs-14);
          color: var(--theme-Second-header-text-color);

          .user-dropdown {
            display: none;
            position: absolute;
            top: 0%;
            right: 0%;
            z-index: 10;
            padding: 10px 0px;
            background-color: var(--theme-body-bg-color);
            box-shadow: 1px 1px 10px rgb(206 206 206 / 40%);
            transition: 0.3s ease all;

            li {
              padding: 8px 30px;

              .SignInUp {
                color: var(--theme-Second-header-text-color);
                font-size: var(--fs-15px);

                &:hover {
                  color: var(--theme-Second-header-hover-color);
                }

                i {
                  margin-right: 10px;
                }
              }

              &:first-child {
                padding: 8px 25px 10px;
                border-bottom: 1px solid var(--second-header-title-color);

                .SignInUp {
                  font-size: var(--fs-16px);
                }
              }
            }

            &::after {
              content: "";
              position: absolute;
              top: -2%;
              right: 2.5%;
              width: 20px;
              height: 20px;
              z-index: 999;
              transform: rotate(45deg);
              background: var(--theme-body-bg-color);
            }
          }

          &:hover {
            color: var(--top-header-text-hover);

            .user-dropdown {
              display: block;
              top: 100%;
            }
          }
        }
      }
    }
  }

  .navbar {
    background-color: var(--theme-Second-header-bg-color);
    padding: 0;
  }

  .container-fluid {
    position: relative;
  }

  .navbar-brand {
    padding: 0;
    display: flex;
    align-items: center;
  }

  .App-Logo.navbar-brand {
    max-width: 200px;
  }

  .navbar-toggler {
    padding: 0;
    border: 0;
    font-size: 24px;

    &:focus {
      box-shadow: none;
    }
  }

  .dropdown-navbar {
    padding: 21px 16px;
    transition: 0.3s ease-in-out all;
    font-size: 15px;
    color: var(--theme-Second-header-title-color);
    font-weight: var(--fw-400);
    cursor: pointer;

    &:after {
      content: "";
      display: block;
      border-bottom: 1px solid var(--theme-Second-header-hover-color);
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }

    &:hover {
      color: var(--theme-Second-header-hover-color);
    }

    .MainNameHeader {
      display: block;
      position: relative;
      text-transform: uppercase;

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 100%;
        height: 100%;
        width: 1px;
        opacity: 1;
        z-index: 10;
        transform: translateX(-50%) scaleY(0);
        transition: all 0.3s ease-in-out;
        background-color: var(--theme-Second-header-hover-color);
      }
    }

    .responsiveArrow {
      display: none;

      .ic_minus {
        display: none;
      }
    }

    &.active {
      .ic_plus {
        display: none;
      }

      .ic_minus {
        display: inline-block;
      }
    }

    .Mega-menu {
      background-color: var(--theme-Second-header-bg-color);
      @media screen and (max-width: 991px) {
        &.my-class {
          opacity: 1;
          visibility: visible;
          min-height: 466px;
          overflow: auto;
        }
      }

      .SubNameHeader {
        font-size: var(--15px);
        color: var(--theme-Second-header-sub-title-color);
        font-weight: var(--fw-700);
        margin-bottom: 10px;
        font-family: var(--title-font);
        font-size: var(--fs-18px);
      }

      .gridView {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      .subNavName {
        color: var(--theme-Second-header-text-color);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 0 10px;
        font-size: var(--fs-14);
        font-weight: normal;

        i {
          font-size: 18px;
        }

        &:hover {
          color: var(--theme-Second-header-hover-color);
        }
      }
    }

    .navbar-name {
      position: relative;
      display: inline-block;
      margin: 0;
      transition: 0.3s ease-in-out all;

      &::before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%) scaleY(0);
        width: 1px;
        height: 33px;
        background-color: var(--second-header-text-hover);
        z-index: 10;
        opacity: 1;
        transition: 0.3s all ease-in-out;
      }
    }

    a.navbar-name {
      &::before {
        content: "";
        display: none;
      }
    }

    .Mega-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      width: 100%;
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      text-transform: capitalize;
      border-top: 1px solid transparent;
      transition: 0.3s ease-in-out all;
      overflow: auto;
      margin-top: -5px;
      padding-top: 5px;

      .float-start {
        width: 33.33%;
      }
    }

    .HeaderImages {
      float: left;
      width: 100%;
      position: relative;
      transition: all 0.4s ease;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      -ms-transition: all 0.4s ease;
      overflow: hidden;

      &::after {
        background: rgba(255, 255, 255, 0.2);
        content: "";
        height: 100%;
        left: 0;
        opacity: 1;
        -moz-opacity: 1;
        -khtml-opacity: 1;
        -webkit-opacity: 1;
        position: absolute;
        top: 0;
        width: 100%;
        transform: scale(0);
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -o-transform: scale(0);
        -ms-transform: scale(0);
        transition: all 1000ms ease-in-out 0s;
        -webkit-transition: all 1000ms ease-in-out 0s;
        -moz-transition: all 1000ms ease-in-out 0s;
        -o-transition: all 1000ms ease-in-out 0s;
        -ms-transition: all 1000ms ease-in-out 0s;
      }

      &:hover {
        &::after {
          transform: scale(1);
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -o-transform: scale(1);
          -ms-transform: scale(1);
          opacity: 0;
        }
      }

      .headerbtn {
        position: absolute;
        bottom: 4px;
        left: 4px;
        z-index: 136;

        a {
          color: var(--theme-top-header-text-color);
          text-decoration: underline;
        }
      }

      .linear {
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 16%, rgba(0, 0, 0, 0.8) 150%);
        position: absolute;
        z-index: 1;
        height: 263px;
        bottom: 0px;
        opacity: 1;
      }
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1200px) {
    .dropdown-navbar {
      .MainNameHeader {
        font-size: var(--fs-14);
      }
    }
  }

  @media screen and (min-width: 0px) and (max-width: 991px) {
    .dropdown-navbar {
      padding: 5px 0px;
    }

    .navbar-collapse.show {
      .navbar-nav {
        &:last-child {
          display: flex;
          border-top: 0px;
          padding: 10px 0px 0px;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          max-width: 90%;
          margin: 0 auto;
        }
      }
    }
  }

  .bg_top_header_color {
    background-color: var(--theme-top-header-bg-color);

    .top-bar-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 6px 0;

      .top-bar-col {
        flex: 1;
      }
    }

    .top-header-text-color {
      color: var(--top-header-text-color);
    }

    .top-header-text-hover:hover {
      color: var(--theme-Second-header-hover-color);
    }

    .top-header-text-size {
      font-size: var(--fs-14);
      overflow-wrap: anywhere;
    }

    .councyBlog {
      background-color: transparent;
      outline: none;
      border: none;
      cursor: pointer;
      margin-left: 10px;
      color: var(--theme-top-header-text-color);
      border-bottom: 1px solid var(--top-header-text-color);
      box-shadow: none;
      border-radius: 0;
      background-image: url("data:image/svg+xml;charset=utf-8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='var(--top-header-text-color)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>");
      padding: 0 10px 0 10px ;
      text-align: center;
      transition: 0.3s ease all;

      option {
        color: #000;
        text-align: center;
      }

      &:hover {
        border-bottom: 1px solid var(--top-header-text-hover);
      }
    }

    @media screen and (max-width: 576px) {
      .user-name {
        li {
          div {
            position: relative;

            i {
              position: unset;

              .user-dropdown {
                &::before {
                  content: "";
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    .dropdown-navbar {
      &:hover {
        .navbar-name {
          position: relative;
          color: var(--theme-Second-header-hover-color) !important;
        }

        .Mega-menu {
          border-top: 1px solid var(--theme-Second-header-hover-color);
          opacity: 1;
          visibility: visible;
          min-height: 466px;
          box-shadow: rgb(206 206 206 / 40%) 0px 15px 10px -15px;
        }
      }
    }
    .megamenu_navbar{
      &:hover{
        .MainNameHeader {
          &:before {
            transform: translate(-50%) scaleX(1);
          }
        }
        &:after {
          transform: scaleX(1);
        }
      }
    }
    
  }
  .user-icon {
    i {
      cursor: pointer;
      position: relative;

      .user-dropdown {
        position: absolute;
        top: 0%;
        right: -25%;
        width: 300px;
        z-index: 1001;
        height: 0px;
        visibility: hidden;
        opacity: 0;
        font-style: normal;
        box-shadow: 1px 1px 10px rgb(189 189 189 / 60%);
        pointer-events: none;

        li {
          padding: 12px;
          margin: 0 15px;

          &:first-child {
            padding: 18px;
            margin: 0;
            font-weight: 500 !important;
            border-bottom: 1px solid #f2f2f2;
          }

          div,
          a {
            transition: 0.2s;

            i {
              font-size: 18px;
              margin-right: 15px !important;
            }
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: -2%;
          right: 1.6%;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          background: var(--theme-body-bg-color);
          width: 10px;
          height: 10px;
          z-index: 11000;
        }
      }
    }
  }

  .nav_shape_image {
    font-size: 18px;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    opacity: 0;
    transition: 0.3s ease-in-out all;
  }

  .visible-title {
    visibility: visible;
  }

  .header {
    justify-content: space-between;
  }
}
@media screen and (max-width: 991px) {
  #header {
    .App-Logo.navbar-brand {
      max-width: 150px;
    }
    #responsive-navbar-nav {
      margin-top: 15px;

      &.main_header {
        .dropdown-navbar {
          text-transform: uppercase;

          &:last-child {
            margin-bottom: 15px;
          }

          .responsiveArrow {
            display: block;
          }

          .Mega-menu {
            position: relative;

            .HeaderImages {
              display: none;
            }
          }
        }
      }
    }
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 250px;
  z-index: 3;
  top: 100%;
  background: var(--theme-Second-header-bg-color);
  a {
    padding: 0 16px;
    text-decoration: none;
    color: var(--theme-Second-header-text-color);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 10px;
    font-size: var(--fs-14);
    font-weight: normal;
  
    i {
      font-size: 18px;
    }
  
    &:hover {
      color: var(--theme-Second-header-hover-color);
    }
    
  }
}
.dropdown:hover .dropdown-content {
  display: block;
}
@media screen and (min-width: 992px) and (max-width: 1400px) {
  #header .dropdown-navbar {
    padding: 22px 8px;
    font-size: 13px;
  }
}

@media (max-width: 992px) {
  #header .dropdown-navbar {
    padding: 12px 0;
    font-size: 15px;
  }
  #header .dropdown-navbar .Mega-menu {
    margin-top: 0;
  }
}
.offer-price{
  text-decoration: line-through;
  text-decoration-style: solid;
  color: #afaeae;
  font-size: 13px !important;
  font-weight: 500;
}