:root {
  // =============================================== START DynamicColorChange ============================================== //
  --bodycontent-title-size: 40px;
  --bodycontent-sub-title-size: 30px;
  --bodycontent-sub-text-size: 18px;
  --bodycontent-text-size: 16px;
  --extrasmall-text-size: 12px;
  --bodycontent-title-resp-size: 24px;

  // Start-Spacer
  --spacer: 16px;
  // End-Spacer

  // Top Header color
  --theme-top-header-bg-color: #f4f4f4;
  --theme-top-header-text-color: #000000;
  --theme-top-header-hover-color: #c19066;

  // Second Header color
  --theme-Second-header-title-color: #231535;
  --theme-Second-header-sub-title-color: #000000;
  --theme-Second-header-text-color: #231535;
  --theme-Second-header-hover-color: #c19066;
  --theme-Second-header-bg-color: #ffffff;

  // slider color
  --theme-slider-title-color: #ffffff;
  --theme-slider-sub-title-color: #ffffff;
  --theme-slider-text-color: #ffffff;
  --theme-slider-hover-color: #231535;

  // Content Color
  --theme-body-title-color: #231535;
  --theme-body-sub-title-color: #231535;
  --theme-body-text-color: #000000;
  --theme-body-hover-color: #c19066;
  --theme-body-bg-color: #f4f4f4;
  --theme-body-sub-bg-color: #ffffff;

  // nav tab color code
  --theme-body-nav-tab-color: #a6a6a6;

  // Footer Color
  --theme-footer-title-color: #000000;
  --footer-sub-title-color: #000000;
  --theme-footer-text-color: #000000;
  --theme-footer-hover-color: #c19066;
  --theme-footer-bg-color: #ffffff;

  // Primary Button Color
  --theme-btn-primary-bg-color: #474747;
  --theme-btn-primary-text-color: #ffffff;
  --theme-btn-primary-hover-color: #c19066;

  // secondary Button Color
  --theme-btn-secondary-bg-color: #ffffff;
  --theme-btn-secondary-text-color: #ffffff;
  --theme-btn-secondary-hover-color: #ffffff;

  //Start-Font-family //
  --body-font: "Roboto", sans-serif;
  --title-font: "Playfair Display";
  //End-Font-family //

  // Start-font-weight
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  // START FONT-SIZE //

  --fs-8px: 8px;
  --fs-10px: 10px;
  --fs-11px: 11px;
  --fs-12px: 12px;
  --fs-13px: 13px;
  --fs-14px: 14px;
  --fs-15px: 15px;
  --fs-16px: 16px;
  --fs-18px: 18px;
  --fs-20px: 20px;
  --fs-22px: 22px;
  --fs-24px: 24px;
  --fs-25px: 25px;
  --fs-28px: 28px;
  --fs-30px: 30px;
  --fs-40px: 40px;
  --fs-50px: 50px;
  --fs-60px: 60px;
  --fs-64px: 64px;
  //END FONT-SIZE //
}

@font-face {
  font-family: "Roboto";
  src: url("../src/Assets/fonts/Roboto-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Roboto";
  src: url("../src/Assets/fonts/Roboto-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Roboto";
  src: url("../src/Assets/fonts/Roboto-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Playfair Display";
  src: url("./../src/Assets/fonts/PlayfairDisplay-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "Playfair Display";
  src: url("./../src/Assets/fonts/PlayfairDisplay-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Playfair Display";
  src: url("./../src/Assets/fonts/PlayfairDisplay-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Playfair Display";
  src: url("./../src/Assets/fonts/PlayfairDisplay-Bold.ttf");
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--title-font);
}
body {
  color: var(--theme-body-text-color);
  background-color: var(--theme-body-bg-color);
  font-family: var(--body-font);
}
.btn {
  color: var(--theme-btn-primary-bg-color);
  padding: 10px 32px;
  border-radius: 0;
  line-height: 20px;
  border: 1px solid var(--theme-btn-primary-bg-color);
  transition: all 0.3s ease-in-out 0s;
  border-radius: 4px;
  z-index: 1;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  &:hover {
    background-color: var(--theme-btn-primary-hover-color);
    border: 1px solid var(--theme-btn-primary-hover-color);
    color: var(--theme-btn-primary-text-color);
  }

  @media screen and (max-width: 575px) {
    font-size: 14px;
    padding: 8px 24px;
  }
}
.btn-white {
  border-color: var(--theme-btn-secondary-bg-color);
  color: var(--theme-btn-secondary-text-color);
  &:hover {
    background-color: var(--theme-btn-secondary-bg-color);
    color: var(--theme-body-title-color);
    border-color: var(--theme-btn-secondary-bg-color);
    &::before {
      background-color: var(--theme-btn-secondary-bg-color);
    }
  }
}
a {
  text-decoration: none;
  cursor: pointer;
  color: var(--theme-body-title-color);
  transition: 0.3s ease-in-out all;
  &:hover {
    color: var(--theme-body-hover-color);
  }
}

.fw-bold {
  font-weight: bold;
}
// toast css
.toast {
  button {
    &.btn-close {
      background: inherit;
      opacity: 1;
    }
    i {
      color: var(--theme-btn-primary-text-color);
    }
  }
}
.prodect_mb {
  margin-bottom: 24px;
  @media (max-width: 575px) {
    margin-bottom: 12px;
  }
}
.diamond-loader {
  min-width: 200px;
  height: 100%;
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s linear;
  width: 100%;

  .loader-1 {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 2px solid;
    border-color: #64748b #64748b transparent;
    -webkit-animation: rotation 1s linear infinite;
    animation: rotation 1s linear infinite;
  }
}

// * keyFrames */

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.product-box {
  background-color: var(--theme-body-sub-bg-color);
  border: 0;
  figure {
    margin: 0;
    background-color: var(--theme-body-sub-bg-color);
  }
  .card-body {
    padding: 10px;
    .product-item-title {
      font-size: 16px;
      font-weight: 400;
      color: var(--theme-body-title-color);
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }
    .product-item-price {
      font-size: 16px;
      font-weight: 500;
      color: var(--theme-body-title-color);
      margin-top: 0px;
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }
    .product-item-text {
      font-size: 14px;
      color: var(--theme-body-text-color);
      margin-top: 0px;
    }
  }
}
.InquiryDetails {
  .modal-dialog {
    max-width: 700px;
    .modal-body {
      height: auto;
    }
  }
}
.modal-dialog {
  .modal-content {
    .modal-header {
      .modal-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--theme-body-title-color);
      }
    }
  }
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--theme-btn-primary-bg-color);
  border-radius: 7px;
  &:hover {
    background: var(--theme-btn-primary-bg-color);
  }
}

// START FONT-SIZE //
.fs-8px {
  font-size: var(--fs-8px);
}
.fs-10px {
  font-size: var(--fs-10px);
}
.fs-11px {
  font-size: var(--fs-11px);
}
.fs-12px {
  font-size: var(--fs-12px);
}
.fs-13px {
  font-size: var(--fs-13px);
}
.fs-14px {
  font-size: var(--fs-14px);
}
.fs-15px {
  font-size: var(--fs-15px);
}
.fs-16px {
  font-size: var(--fs-16px);
}
.fs-18px {
  font-size: var(--fs-18px);
}
.fs-20px {
  font-size: var(--fs-20px);
}
.fs-22px {
  font-size: var(--fs-22px);
}
.fs-24px {
  font-size: var(--fs-24px);
}
.fs-25px {
  font-size: var(--fs-25px);
}
.fs-28px {
  font-size: var(--fs-28px);
}
.fs-30px {
  font-size: var(--fs-30px);
}
.fs-40px {
  font-size: var(--fs-40px);
}
.fs-50px {
  font-size: var(--fs-50px);
}
.fs-60px {
  font-size: var(--fs-60px);
}
.fs-64px {
  font-size: var(--fs-64px);
}
// START FONT-SIZE End //

// START SPACER //

.spacerT {
  margin-top: var(--spacer);
}
.spacerY {
  margin-top: var(--spacer);
  margin-bottom: var(--spacer);
}
.spacerB {
  margin-bottom: var(--spacer);
}

// END SPACER //

// === START title SIZE ===//
.large-main-title {
  font-size: var(--font-size-50);
}
.main-title {
  font-size: var(--bodycontent-sub-title-size);
}
.medium-title {
  font-size: var(--bodycontent-sub-text-size);
}
.medium-small-title {
  font-size: var(--bodycontent-text-size);
}
.small-title {
  font-size: var(--fs-14px);
}
.extra-small-title {
  font-size: var(--extrasmall-text-size);
}

// ===END title SIZE === //

// Start Input-Text
.input-text {
  outline: 0;
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 4px;
  padding: 10px;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--theme-body-text-color);
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  box-shadow: none !important;
  &:focus {
    border: 1px solid rgba(229, 229, 229, 1);
    box-shadow: none;
  }
}

.custome-select {
  .css-13cymwt-control,
  .css-t3ipsp-control {
    border-color: #e5e5e5;
    min-height: auto;
    box-shadow: none;
    border-radius: 0;
    cursor: pointer;
    font-size: 14px;

    & > div:first-child {
      padding: 6px;

      .css-1jqq78o-placeholder {
        color: #64748b;
        line-height: 20px;
      }
    }

    .css-1u9des2-indicatorSeparator {
      display: none;
    }

    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: #e5e5e5;
      background-color: #fff;
    }
  }

  .input-group {
    .css-13cymwt-control,
    .css-t3ipsp-control {
      border-radius: 0;
    }
  }
}

// End Input-Text
.input-icon {
  border: 1px solid var(--theme-btn-primary-bg-color);
  padding: 11px;
  background-color: var(--theme-btn-primary-bg-color);
  color: var(--theme-btn-primary-text-color);
  border-right: 0px solid transparent;
  font-size: 18px;
}

.input-icontext {
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 0;
  padding: 10px;
  border: 1px solid var(--theme-btn-primary-bg-color);
  background-color: var(--theme-btn-primary-bg-color);
  color: var(--theme-btn-primary-text-color);
  border-right: 0px solid transparent;
  font-size: 15px;
}
.input-warning {
  color: var(--bodycontent-color-Warning);
}
.input-eye-icon {
  position: absolute;
  top: 55%;
  right: 3%;
  transform: translateY(-55%);
  cursor: pointer;
}
// END INPUT//
// ============================================================== START FILE UPLOAD ============================================================== //
.file-upload {
  position: relative;
  border: 1px dashed rgb(168, 168, 168);
  border-radius: 0 !important;
  background-color: #f5f5f5;
  padding: 10px;
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.file-upload > input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.close-icon {
  position: absolute;
  top: 9px;
  right: 5px;
  color: red;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff59;
  cursor: pointer;

  i {
    font-weight: 700;
    font-size: 12px !important;
  }
}

// ============================================================== END FILE UPLOAD ============================================================== //

// ============================================================== START FILTTER BOX ============================================================== //

.fluorescence-ul {
  display: flex;
  flex-wrap: wrap;
  box-shadow: none;

  .images-li {
    width: 125px;
    &.master_stone_shape {
      width: 95px;
      .fluorescence-li {
        padding: 5px;
      }
    }
    @media (max-width: 575px) {
      width: 110px;
      .fluorescence-li {
        word-break: break-all;
      }
    }
  }

  li {
    flex: 0 0 auto;
    list-style: none;
    padding: 0px 5px 5px 0;
    cursor: pointer;
    box-shadow: none;
    .fluorescence-images {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      img {
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        border-radius: 0;
        width: 65px;
        overflow: hidden;
        box-shadow: none;
      }
      i {
        font-size: 38px;
        margin-bottom: 5px;
      }
    }

    .fluorescence-li {
      border: 1px solid var(--theme-body-text-color);
      padding: 8px 8px;
      margin: 0;
      cursor: pointer;
      color: var(--theme-body-text-color);
      transition: 0.3s ease-in-out all;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 90px;
      background-color: var(--theme-body-bg-color);
      flex-wrap: wrap;
      border-radius: 4px;
      text-align: center;
      .color-code {
        position: absolute;
        top: 0;
        left: 0;
        width: 8px;
        height: 100%;
      }
    }

    .fluorescence-li-disabled-property {
      position: relative;
      pointer-events: none;
      opacity: 0.4;
      cursor: not-allowed !important;
      background-color: var(--theme-body-sub-bg-color);
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to top right,
          transparent calc(50% - 0.5px),
          (rgb(165 164 164 / 40%), rgb(165 164 164 / 40%)),
          transparent calc(50% + 0.5px)
        );
        transition: all 0.2s ease-in-out;
      }
    }

    .card {
      box-shadow: none;
      border: none;
    }

    &:hover {
      .fluorescence-li {
        border: 1px solid var(--theme-body-text-color);
        color: var(--theme-body-text-color);
      }
    }
  }
  .colorpopup {
    & + .colorpopup {
      margin-left: 8px;
    }
  }
}

// ============================================================== START FILTTER BOX ============================================================== //

// ============================================================== START TABLE COLOR ============================================================== //
.table {
  .Table-Header-BackColor {
    background-color: var(--theme-body-nav-tab-color);
    color: var(--theme-body-title-color);
    font-size: 14px;
    td {
      white-space: nowrap;
    }
  }
  .Table-Body-BackColor {
    td {
      color: var(--theme-body-text-color);
      font-size: 14px;
      vertical-align: middle;
      white-space: nowrap;
    }
  }
}

// ============================================================== END TABLE COLOR ============================================================== //

// ============================================================== START MODAL CUSTOM ============================================================== //
.modal {
  .modal-content {
    border-radius: 0;
    background-color: var(--theme-body-sub-bg-color);
    .modal-body {
      height: 500px;
      overflow: auto;
    }
  }
  &.show {
    background-color: rgba(0, 0, 0, 0.555);
  }
}
.modal-open {
  padding-right: 0;
}
.btn-close {
  box-shadow: none;
}

// ==========END MODAL CUSTOM ======== //

// ========= START LOADER ======== //

.loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  animation: bg 0.1s;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  * {
    pointer-events: none;
  }
}

// ============== END LOADER ============ //

// ============== Start Breadcrumb Css ============== //
.page-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  li {
    list-style: none;
    position: relative;
    padding-right: 20px;
    &:before {
      position: absolute;
      content: ">";
      padding-right: 7px;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 12px;
      color: var(--theme-body-text-color);
      opacity: 0.5;
    }
    &:last-child {
      padding-left: 0px;
      &:before {
        display: none;
      }
    }
    &:first-child {
      padding-left: 0px;
    }
  }
}

// ============= End Breadcrumb Css ===========//

// ============= START UL LI ============= //
ul {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
  }
}

// ==================================== END UL LI ==================================== //

// ==================================== START VIDEO ICON ==================================== //
.video-icon {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.233);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    font-size: var(--bodycontent-sub-text-size);
    color: #fff;
  }
}

// ========END VIDEO ICON ======== //

// ======== START MAP =========//
.map-contact {
  overflow: hidden;
  background: none;
  height: 500px;
  width: 100%;
}
// ========= END MAP ======== //

// ======== START CURSOR POINTER ======= //
.cursor-pointer {
  cursor: pointer;
}
// ========= END CURSOR POINTER ======== //

// ======= START DEFAULT CSS =======//

.overlayView > a.testMode {
  z-index: 100000000000;
}

iframe {
  .overlayView {
    z-index: 100000000000000000;
    position: absolute !important;
  }
}

.stoon {
  min-height: 80vh;
}
.DiamondsInfoPrice {
  color: var(--theme-body-title-color);
  font-size: 22px;
  font-weight: var(--fw-700);
  margin-top: 5px;
}
// ==================================== END DEFAULT CSS ==================================== //

// ======================================================== //

.container {
  max-width: 1420px;
}
.heading-title {
  font-size: 40px;
  font-weight: var(--fw-600);
  color: var(--theme-body-title-color);
  word-break: normal;
}
// ============================================== //

// ============================================== //
#loader {
  .loader {
    span {
      animation: 0.75s linear 0s infinite normal both running react-spinners-ClipLoader !important;
    }
  }
}

@keyframes react-spinners-ClipLoader {
  0% {
    transform: rotate(0deg) scale(1);
  }

  50% {
    transform: rotate(180deg) scale(1);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}

// ==========================================================//

.bodycontent-title-size {
  font-size: var(--bodycontent-title-size);
}

.bodycontent-text-size {
  font-size: var(--bodycontent-text-size);
  word-break: break-all;
}

.bodycontent-sub-title-size {
  font-size: var(--bodycontent-sub-title-size);
  word-break: break-all;
}

.bodycontent-small-text-size {
  font-size: var(--bodycontent-small-text-size);
  word-break: break-all;
}
@media (max-width: 576px) {
  .bodycontent-title-size {
    font-size: var(--bodycontent-title-resp-size);
  }
}

.Header-Active-Color {
  color: var(--theme-Second-header-hover-color) !important;
  font-weight: 500 !important;
}

.word-break {
  word-break: break-all;
}
.fw-900 {
  font-weight: 800;
}
.fw-600 {
  font-weight: 600;
}
.fw-500 {
  font-weight: 500;
}
.fw-400 {
  font-weight: 400;
}
.fw-300 {
  font-weight: 300;
}
.h-img {
  max-width: 100%;
}
.section_margin {
  margin: 30px 0;
  @media screen and (max-width: 575px) {
    margin: 12px 0;
  }
}
.theame-square-button {
  border: 1px solid var(--theme-btn-primary-bg-color);
  color: var(--theme-btn-primary-text-color);
  background-color: var(--theme-btn-primary-bg-color);
  padding: 5px 25px 5px 25px;
  box-shadow: none;
  transition: 0.3s ease-in-out all;
  &:hover {
    border: 1px solid var(--theme-btn-primary-hover-color);
    color: var(--theme-btn-primary-text-color);
    background-color: var(--theme-btn-primary-hover-color);
  }
}
.button-thamebalck {
  background-color: var(--theme-btn-primary-bg-color);
  color: var(--theme-btn-primary-text-color);
  outline: none;
  border: 1px solid var(--theme-btn-primary-bg-color);
  &:hover,
  &:active {
    background-color: var(--theme-btn-primary-hover-color);
    color: var(--theme-btn-primary-text-color);
    border-color: var(--theme-btn-primary-hover-color);
  }
}
.copnbutton {
  background-color: var(--theme-btn-primary-bg-color);
  color: var(--theme-btn-primary-text-color);
  outline: none;
  border: 1px solid var(--theme-btn-primary-bg-color);
  transition: 0.3s ease-in-out all;
  padding: 5px 25px 5px 25px;
  min-height: 42px;
  line-height: 30px;
  box-shadow: none;
  display: inline-block;
  border-radius: 0px;

  &:hover {
    background-color: var(--theme-btn-primary-hover-color);
    color: var(--theme-btn-primary-text-color);
    border-color: var(--theme-btn-primary-hover-color);
  }
}
.theme-color {
  background-color: var(--theme-btn-primary-bg-color);
  color: var(--theme-btn-primary-text-color);
  outline: none;
  border: 1px solid var(--theme-btn-primary-bg-color);
  transition: 0.3s ease-in-out all;

  &:hover {
    background-color: var(--theme-btn-primary-hover-color);
    color: var(--theme-btn-primary-text-color);
    border-color: var(--theme-btn-primary-hover-color);
  }
}
.button-transparent {
  border: 1px solid var(--theme-body-text-color);
  padding: 5px 20px;
  transition: -0.7s ease all;
  border-radius: 0px;
  color: var(--theme-body-text-color);
  &:hover,
  &.active {
    background-color: var(--theme-btn-primary-bg-color);
    color: var(--theme-btn-primary-text-color);
  }
  span {
    transition: 0.2s;
  }
}
.backbtn {
  background-color: transparent;
  border: 0px;
  color: var(--theme-body-text-color);
  &:hover {
    color: var(--theme-body-hover-color);
  }
}
.fs-35 {
  font-size: var(--fs-30px);
}
.prodert_title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 5px;
  color: var(--theme-body-title-color);
}
.prodert_details_sku {
  color: var(--theme-body-text-color);
  font-size: var(--fs-14px);
  margin-bottom: 5px;
}
.weight_info {
  display: flex;
  flex-wrap: wrap;
  .Gold_Weight {
    margin: 0 20px 5px 0px;
    font-size: var(--fs-13px);
    .text-bold {
      font-weight: var(--fw-700);
    }
  }
}
.diamonds_info {
  .text {
    font-size: var(--fs-14px);
  }
}
.product_details_info {
  .ab_details_info {
    .ab_details_call {
      .ab_details_title {
        font-weight: var(--fw-500);
        font-size: var(--fs-14px);
      }
    }
  }
}
.main-title01 {
  color: var(--theme-body-title-color);
}

.main-sub-title {
  color: var(--theme-body-sub-title-color);
}

.content-text-color {
  color: var(--theme-body-text-color);
}

.content-hover:hover {
  color: var(--theme-body-hover-color);
}

.offcanvas {
  background-color: var(--theme-body-bg-color);
}
.sec-bg-color {
  background-color: var(--theme-body-sub-bg-color);
}
.like-btn {
  color: var(--theme-btn-primary-bg-color);
  margin-left: 15px;
}

// Container
.container-simple {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container-simple {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-simple {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-simple {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-simple {
    max-width: 1140px;
  }
}

.product-detail-tab {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  li.nav-item {
    white-space: nowrap;
  }
}

.card_body {
  color: var(--theme-body-text-color);
  box-shadow: none;
}
.Add_Address {
  .modal-dialog {
    max-width: 1140px;
    width: 95%;
    margin: auto;
  }
  .modal-header {
    display: block;
    border: 0;
  }
  .modal-body {
    height: auto;
    .address_label {
      font-weight: 500;
      margin-bottom: 3px;
      color: var(--theme-body-title-color);
    }
  }
}

// ======================= Focus ======================= //
.accordion-button:focus {
  box-shadow: none;
}
// ======================= Focus ======================= //

// ======================= Heading resp ======================= //
@media (max-width: 576px) {
  .heading-resp {
    font-size: 24px;
  }
}
// ======================= Heading resp ======================= //

.figure {
  display: flex;
  justify-content: center;
  img {
    max-height: 100%;
  }
}

.table-detail {
  th,
  td {
    white-space: nowrap;
  }
}
.specification_tabs {
  .diamond_info_title {
    font-size: 20px;
    font-weight: var(--fw-500);
    margin-bottom: 15px;
    color: var(--theme-body-title-color);
  }
  & > div {
    &:last-child {
      .diamond_info_title {
        margin-top: 20px;
      }
    }
  }
}
.offcanvas-items {
  padding: 8px;
  background-color: var(--theme-body-sub-bg-color);
  margin-bottom: 8px;
  .CartItems {
    display: flex;
    flex-wrap: wrap;
    .CartItems_left {
      max-width: 100px;
      padding-right: 10px;
    }
    .CartItems_right {
      flex: 1;
      .CartItems_title {
        font-weight: var(--fw-600);
        margin-bottom: 2px;
        font-size: 14px;
      }
      .CartItems_price {
        font-size: 12px;
        font-weight: var(--fw-600);
        margin-top: 2px;
      }
      .CartItems_qty {
        font-size: 12px;
      }
    }
  }
}
