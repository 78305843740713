#register {
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
  .modal-content {
    width: 100%;
    overflow-y: hidden;
  }

  .colse-icon {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
    @media (max-width: 575px){
      top: 5px;
      right: 5px;
      font-size: 16px;
    }
  }

  .banner_img {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .login-field {
    display: flex;
    align-items: center;
    padding: 20px 15px;
    .button-thamebalck {
      width: 100%;
    }
  }
  .login_info_inner{
    display: flex;
    flex-wrap: wrap;
    .login_info_left{
      flex: 1;
    }
    .login_info_right{
      flex: 1;
    }
  }
}
