body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  height: 100% ;
}
html {
  scroll-behavior: smooth;
}