#login {
  .text-decoration-underline {
    cursor: pointer;
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  .modal-content {
    width: 100%;
    overflow-y: hidden;
    border: 1px solid transparent;
  }

  .colse-icon {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
    @media (max-width: 575px){
      top: 5px;
      right: 5px;
      font-size: 16px;
    }
  }
  .banner_img {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .login-field {
    width: 100%;
    padding: 40px;
    .button-thamebalck {
      width: 100%;
    }
    @media (max-width: 575px){
      padding: 30px 20px ;
    }
  }
  .login_info_inner{
    display: flex;
    flex-wrap: wrap;
    .login_info_left{
      flex: 1;
    }
    .login_info_right{
      flex: 1;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1366px) {
 
  .modal-dialog {
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 991px){
  .login_info_left{
   display: none;
  }
  .modal-dialog{
   max-width: 500px;
   margin: 0 auto;
  }
}


.modal.show .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}

.without_images {
  --bs-modal-width: 700px;
}
